@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-gray-back;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1 {
    @apply mb-6 text-4xl lg:text-5xl text-gray-50 font-light text-center uppercase;
    @apply underline underline-offset-4 decoration-2 decoration-orange-bright;
  }

  h2 {
    @apply text-2xl lg:text-3xl text-gray-50 font-bold p-2;
    @apply bg-gray-dark border-2 border-l-[16px] border-solid border-orange-bright;
  }

  h3, label {
    @apply text-xl lg:text-2xl text-gray-50 font-light;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  footer {
    @apply text-lg text-gray-50 font-light;
    @apply mt-8 pt-2 border-t-2 border-solid border-orange-bright;
  }

  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    border: 2px solid;
    @apply border-gray-light;

    border-radius: 3px;
    box-sizing: border-box;
    @apply text-xl text-orange-dark p-1 bg-gray-50;
  }

  select:focus {
    box-shadow: 0 0 8px #05BAEA;
  }

  b, strong {
    font-weight: 700;
  }

  input[type="range"] {
    margin: auto;
    -webkit-appearance: none;
    position: relative;
    overflow: hidden;
    height: 20px;
    width: 160px;
    cursor: pointer;
    border-radius: 0;
  }

  input[type=range]::-webkit-slider-runnable-track {
    @apply bg-gray-50;
  }

  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    @apply bg-orange-dark;
    box-shadow: -200px 0 0 200px theme('colors.orange-dark');
    border: 2px solid;
    @apply border-gray-50;
  }

  input[type=range]:focus {
    outline: none;
  }

  button {
    @apply pl-8 pr-8 pt-4 pb-4 bg-gray-med border-2 border-orange-bright border-solid cursor-pointer;
    @apply text-2xl text-gray-50 font-light uppercase;
  }

  button:hover {
    @apply bg-orange-dark border-orange-dark text-gray-dark;
  }

  button:active {
    @apply bg-orange-bright border-orange-bright text-gray-dark;
  }

  textarea {
    @apply text-base text-orange-dark p-1 bg-gray-50;
  }
}

@layer components {
  .setting-value {
    @apply mr-4 text-3xl text-orange-dark font-semibold;
  }

  .input-group-class {
    @apply mb-6;
  }

  .checkbox-input-group input[type="checkbox"] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .checkbox-input-group input:checked + label > svg {
    height: 28px;
    /*animation: draw-checkbox ease-in-out 0.2s forwards;*/
  }

  .checkbox-input-group label:hover::after {
    border: 2px solid;
    @apply border-gray-light;
  }

  .checkbox-input-group label:active::after {
    @apply bg-gray-light;
  }

  .checkbox-input-group label {
    cursor: pointer;
    line-height: 26px;
    position: relative;
  }

  .checkbox-input-group label::after {
    @apply bg-gray-50;
    border: 2px solid transparent;
    content: "";
    float: left;
    height: 28px;
    margin-right: 0.5rem;
    transition: 0.15s all ease-out;
    width: 28px;
  }

  .checkbox-input-group svg {
    fill: theme('colors.orange-dark');
    height: 0;
    left: -36px;
    position: absolute;
    stroke-dasharray: 33;
    stroke-width: 2px;
    top: 1px;
    width: 28px;
  }

  @keyframes draw-checkbox {
    0% {
      stroke-dashoffset: 33;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }


  @keyframes bounceIn {
    0%, 20%, 40%, 60%, 80%, to {
      -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
      animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }
    0% {
      opacity: 0;
      -webkit-transform: scale3d(.3, .3, .3);
      transform: scale3d(.3, .3, .3)
    }
    20% {
      -webkit-transform: scale3d(1.1, 1.1, 1.1);
      transform: scale3d(1.1, 1.1, 1.1)
    }
    40% {
      -webkit-transform: scale3d(.9, .9, .9);
      transform: scale3d(.9, .9, .9)
    }
    60% {
      opacity: 1;
      -webkit-transform: scale3d(1.03, 1.03, 1.03);
      transform: scale3d(1.03, 1.03, 1.03)
    }
    80% {
      -webkit-transform: scale3d(.97, .97, .97);
      transform: scale3d(.97, .97, .97)
    }
    to {
      opacity: 1;
      -webkit-transform: scaleX(1);
      transform: scaleX(1)
    }
  }

  .animate__bounceIn {
    -webkit-animation-duration: .75s;
    animation-duration: .75s;
    -webkit-animation-duration: calc(var(--animate-duration) * .75);
    animation-duration: calc(var(--animate-duration) * .75);
    -webkit-animation-name: bounceIn;
    animation-name: bounceIn
  }
}
